import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Home from '~/pages/Home';
import Login from '~/pages/Login';

import Contacts from '~/pages/Contacts';
import ContactsForm from '~/pages/Contacts/form';
import Categories from '~/pages/Categories';
import SubCategories from '~/pages/SubCategories';

import Customers from '~/pages/Customers';

import editForm from '~/pages/Customers/editForm';

import Products from '~/pages/Products';
import ProductsForm from '~/pages/Products/form';

import Contracts from '~/pages/Contracts';

import Users from '~/pages/Users';
import Roles from '~/pages/Roles';
import Remember from '~/pages/Password/Remember';
import Reset from '~/pages/Password/Reset';

import RegisterW41 from '~/pages/RegisterW41/RegisterW41';

import Logs from '~/pages/Logs';

import e404 from '~/pages/Errors/e404';
import InventoryBrands from '~/pages/InventoryBrands';
import InventoryBrandForm from '~/pages/InventoryBrands/form';
import InventoryModels from '~/pages/InventoryModels';
import InventoryCategories from '~/pages/InventoryCategories';
import InventorySubCategories from '~/pages/InventorySubCategories';
import InventoryProducts from '~/pages/InventoryProducts';
import InventoryProductsForm from '~/pages/InventoryProducts/form';
import InventoryProductsTags from '~/pages/InventoryProducts/tags';
import Twofa from '~/pages/Login/2fa-login';
import Route from './Route';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/2fa-login" component={Twofa} isPrivate />
      <Route exact path="/password/link" component={Remember} />
      <Route exact path="/password/reset/:token" component={Reset} />

      <Route exact path="/home" component={Home} isPrivate />

      <Route exact path="/contacts" component={Contacts} isPrivate />
      <Route exact path="/contacts/:id/edit" component={ContactsForm} isPrivate />

      <Route exact path="/contracts" component={Contracts} isPrivate />

      <Route exact path="/customers" component={Customers} isPrivate />
      <Route exact path="/customers/create" component={editForm} isPrivate />
      <Route exact path="/customers/:id/edit" component={editForm} isPrivate />

      <Route exact path="/products" component={Products} isPrivate />
      <Route exact path="/products/create" component={ProductsForm} isPrivate />
      <Route exact path="/products/:id/edit" component={ProductsForm} isPrivate />

      <Route exact path="/categories" component={Categories} isPrivate />
      <Route exact path="/subcategories" component={SubCategories} isPrivate />

      <Route exact path="/contracts/:type" component={Contracts} isPrivate />

      <Route exact path="/users" component={Users} isPrivate />
      <Route exact path="/roles" component={Roles} isPrivate />

      <Route exact path="/logs" component={Logs} isPrivate />

      <Route exact path="/inventoryBrands" component={InventoryBrands} isPrivate />
      <Route exact path="/inventoryBrands/create" component={InventoryBrandForm} isPrivate />
      <Route exact path="/inventoryBrands/:id/edit" component={InventoryBrandForm} isPrivate />
      <Route exact path="/inventoryModels" component={InventoryModels} isPrivate />
      <Route exact path="/inventoryCategories" component={InventoryCategories} isPrivate />
      <Route exact path="/inventorySubCategories" component={InventorySubCategories} isPrivate />

      <Route exact path="/inventoryProducts" component={InventoryProducts} isPrivate />
      <Route exact path="/inventoryProducts/create" component={InventoryProductsForm} isPrivate />
      <Route exact path="/inventoryProducts/:id/edit" component={InventoryProductsForm} isPrivate />

      <Route exact path="/w41" component={RegisterW41} isPrivate />

      <Route exact path="/patrimonio/:tag" component={InventoryProductsTags} isPrivate />

      <Route exact path="/404" component={e404} isFree />
      <Redirect to="/404" />
    </Switch>
  );
}
