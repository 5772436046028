import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { message, Modal, Spin, Row } from 'antd';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';

export default function InventoryModelsForm({ visible, recordID, onClose }) {
  /* eslint-disable react/jsx-wrap-multilines */
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState({});
  const [brandOptions, setBrandOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const permissions = useSelector(state => state.user && state.user.permissions);

  const { Option } = Select;

  const fetchRecord = async () => {
    setLoading(true);
    try {
      if (recordID) {
        const response = await api.get(`/inventoryModels/${recordID}`);
        const data = JSON.parse(response.data);
        if (data.inventoryBrand.deleted_at !== null) {
          brandOptions.push(data.inventoryBrand);
          setBrandOptions(brandOptions);
        }
        setRecordData(data);
      } else {
        setRecordData({});
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      if (values.id) {
        await api.put(`/inventoryModels/${values.id}`, values);
        message.success(t('messages:successUpdateInventoryModel'));
      } else {
        await api.post('/inventoryModels', values);
        message.success(t('messages:successInventoryModel'));
      }
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const fetchBrands = async () => {
    try {
      const { data } = await api.get('/inventoryBrands');
      setBrandOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchBrands();
      fetchRecord();
    }
  }, [visible]);

  const brandSchema = Yup.object().shape({
    name: Yup.string()
      .min(3)
      .required(),
    inventory_brand_id: Yup.string().required(),
  });

  return (
    <div>
      <Formik
        initialValues={recordData}
        enableReinitialize
        onSubmit={handleSave}
        validationSchema={brandSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ isSubmitting, submitForm, errors, resetForm }) => (
          <Modal
            visible={visible}
            afterClose={resetForm}
            onCancel={onClose}
            title={
              recordData.id
                ? t('screens:inventoryModels.modal.title.edit', {
                    record: `${t(recordData.id)} - ${t(recordData.name)}`,
                  })
                : t('screens:inventoryModels.modal.title.add')
            }
            footer={
              <ModalFooter
                type={permissions && !permissions.includes('@config/edit') && 'info'}
                onOk={submitForm}
                loading={loading || isSubmitting}
                onCancel={onClose}
              />
            }
          >
            <Spin spinning={loading || isSubmitting}>
              <Form>
                <input type="hidden" name="id" value={recordData.id} />
                <Row>
                  <FormControl
                    cols={{ xs: 24 }}
                    error={errors.inventory_brand_id}
                    field="inventory_brand_id"
                    label={t('screens:inventoryModels.data.inventoryBrand')}
                    required
                  >
                    <Select
                      style={{ textTransform: 'uppercase' }}
                      name="inventory_brand_id"
                      disabled={permissions && !permissions.includes('@config/edit') && 'info'}
                    >
                      {brandOptions.map(item => {
                        return (
                          <Option key={item.id} value={item.id} style={{ textTransform: 'uppercase' }}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl
                    cols={{ xs: 24 }}
                    error={errors.name}
                    field="name"
                    label={t('screens:inventoryModels.data.name')}
                    required
                  >
                    <Input
                      name="name"
                      style={{ textTransform: 'uppercase' }}
                      autoFocus
                      disabled={permissions && !permissions.includes('@config/edit')}
                    />
                  </FormControl>
                </Row>
              </Form>
            </Spin>
          </Modal>
        )}
      </Formik>
    </div>
  );
}

InventoryModelsForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  recordID: PropTypes.number,
  onClose: PropTypes.func,
};

InventoryModelsForm.defaultProps = {
  recordID: null,
  onClose: () => {},
};
